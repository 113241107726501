import { render, staticRenderFns } from "./index.vue?vue&type=template&id=360b471e&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=360b471e&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360b471e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/game-medium-admin/game-medium-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('360b471e')) {
      api.createRecord('360b471e', component.options)
    } else {
      api.reload('360b471e', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=360b471e&scoped=true", function () {
      api.rerender('360b471e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Accessories/AccessoryCategories/index.vue"
export default component.exports